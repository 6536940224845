import React, {useState, useEffect, useContext} from "react";
import { TeamManagementContext } from "./teammanagementContext"
import FormInput from "../../uicomponents/form-input/forminput";
import FutworkButton from "../../uicomponents/button";
import styles from "../../assets/stylesheets/pages/teammanagement/teamlead.module.scss"
import { addAgentToTL, getAgentDetails } from "./service"
import { ErrorIcon, PlusIcon, TrashIcon } from "../../assets/images";
import checkIsEmpty from "../../helper/checkIsEmpty"
import capitalise from "../../helper/Capitalise";

const AddAgent = () => {
    const { teamSelected } = useContext(TeamManagementContext)
    const [mobileNumber, setMobileNumber] = useState("")
    const [errorMsg, setErrorMsg] = useState({
        input_value: ''
     })
     const [apiResponse, setApiResponse] = useState({
        status: '',
        msg: '',
        loading: false
    })
    const [agentsReady, setAgentsReady] = useState('')
    const [agentSelected, setAgentSelected] = useState([])
    const [finalSubmit, setFinalSubmit] = useState({
        status: '',
        msg: '',
        loading: false
    })

    const isNumeric = (str) => {
        if (typeof str != "string") return false
        return !isNaN(str) && !isNaN(parseFloat(str))
    }

    const formatCallerStatus = (status) => {
        switch (status) {
            case 'looking_to_work':
                return `Looking for a job`
            case 'working':
                return `Working`
            case 'ban':
                return `Ban`
            case 'not_looking_to_work':
                return `Not looking to work`
            case 'on_a_break':
                return `On a break`
            default:
                return status ? capitalise(status.replace(/_/g, " ")) : 'NA'
        }
    }

    const styleStatus = (status) => {
        switch (status) {
            case 'looking_to_work':
                return `${styles.caller_status_styling} ${styles.idle}`
            case 'working':
                return `${styles.caller_status_styling} ${styles.active}`
            case 'ban':
                return `${styles.caller_status_styling} ${styles.ban}`
            case 'not_looking_to_work':
                return `${styles.caller_status_styling} ${styles.not_looking_to_work}`
            case 'on_a_break':
                return `${styles.caller_status_styling} ${styles.on_a_break}`
            default:
                return `${styles.caller_status_styling} ${styles.not_looking_to_work}`
        }
    }

    const checkIfValid = () => {
        let validationErrors = {};
        // Simple validation for empty fields
        if(mobileNumber.length < 10 || !isNumeric(mobileNumber)){
            validationErrors['input_value'] = 'Please enter a valid 10 digit mobile number'
        }
        if (Object.keys(validationErrors).length > 0) {
            setErrorMsg(validationErrors);
            return false;
        } else {
            return true
        }
    }

    const checkIfAgentIsAlreadyLinkedToTeam = async() => {
        setApiResponse({status: '', msg: '', loading: true})
        try {
            let result = await getAgentDetails(mobileNumber)
            const {team, id: agentId, firstname, lastname, mobile, workStatus} = result
            if(team &&  team.teamLeadId){
                setApiResponse({
                    status: 400,
                    msg: `This agent is already assigned to a TL`,
                    loading: false
                })
              
            }else{
                setAgentsReady({
                    agentId, 
                    mobile, 
                    firstname, 
                    lastname,
                    workStatus
                })
            }
        } catch (error) {
            const { response : {data} } = error
            setApiResponse({
                status: 400,
                msg: data && data.error ? data.error : `Something went wrong`,
                loading: false
            })
        }
    }

    const addAgentToTeam = async() => {
        const {id: teamLeadId} = teamSelected
        const arrayOfAgentId = agentSelected && agentSelected.map(item => item.agentId)

        const payload = {
            agents: arrayOfAgentId,
            teamLeadId
        }

        try {
            setFinalSubmit({status: '', loading: true, msg: ''})
            const result = await addAgentToTL(payload)
            if(result && result.data){
                setFinalSubmit({
                    loading: false,
                    msg: result.data.msg,
                    status: 200
                })
            }
        } catch (error) {
            const {response: {data}} = error
            setFinalSubmit({
                status: 400,
                msg: data && data.error ? data.error : `Something went wrong`,
                loading: false
            })
        }
    }

    const isSearchDisabled = () => {
        if(mobileNumber){
            if(agentsReady) return true
            // if(apiResponse.loading) return true
            // else 
            return false
        }else{
            return true
        }
    }

    const unselect = (idx) => {
        const updatedData = agentSelected.filter((item, id) => id !== idx)
        setAgentSelected(updatedData)
    }

    return(
        <div className={styles.add_agent_wrapper}>
        <div className={styles.mobile_input}>
            <div className={styles.input_field}>
                <FormInput
                    label="Mobile no."
                    helperIcon={true}
                    helperText={'Enter agent mobile no. to add in the team'}
                    key={'mobile'}
                    placeholder="Enter mobile number"
                    onChange={(e) => {
                         // Clear error for a field once it has been changed
                         if (errorMsg['input_value']) {
                            setErrorMsg(prevErrors => ({
                                ...prevErrors,
                                ['input_value']: null
                            }));
                        }
                        setApiResponse({status: '', loading: false, msg: ''})
                        setMobileNumber(e.target.value)
                        // validateGoogleSheetLink(e.target.value)
                        // getFormData('sheetId', e.target.value)
                    }}
                    onKeyUp={(event) => {
                        if (event.key === 'Enter') {
                            if(checkIfValid()) 
                                checkIfAgentIsAlreadyLinkedToTeam()
                        }
                    }}
                    // value={item.sheetId}
                />
            </div>
            <FutworkButton buttonStyle={'navy--solid non-rounded'} buttonSize={'sm'} disabled={isSearchDisabled()} onClick={() => {
                if(checkIfValid())  checkIfAgentIsAlreadyLinkedToTeam()
            }}>Search</FutworkButton>
        </div>
        {
            errorMsg && errorMsg.input_value ? 
            <div  className={`${styles['error-msg']}`}>
                <img src={ErrorIcon} />{errorMsg.input_value}</div> : null
        }
        {
            apiResponse.status !== 200 && apiResponse.status !== '' ?
                <div className={`${styles['error-msg']}`}>
                     <img src={ErrorIcon} />{apiResponse.msg}
                </div> : null
        }


        {/* show agents eligible to be added to the team  */}
        <div className={styles.agent_ready_wrapper}>
        {
            agentsReady  ?
            <div className={styles.agent_ready}>
                <div className={styles.agent_details}>
                    <span>{`${agentsReady.firstname} ${agentsReady.lastname}`}</span>
                    <span>{agentsReady.mobile}</span>
                    <span className={styleStatus(agentsReady.workStatus)}>{formatCallerStatus(agentsReady.workStatus)}</span>
                </div>
                <div>
                    <img src={PlusIcon} className={styles.select_agent_icon} onClick={()=> {
                        setAgentSelected([...agentSelected, agentsReady])
                        setAgentsReady('')
                    }
                    }/>
                </div>
            </div>
            : null
        }
        </div>


        {/* show agents selected to be added in the team */}
        <div className={styles.eligible_agents_list_wrapper}>
        {agentSelected && agentSelected.length > 0 ?
        <>
        <b>Selected Agents</b>
        <div className={styles.eligible_agents_list_section}>
            {
                agentSelected && agentSelected.map((agent, idx) => {
                    return <div className={styles.eligible_agents_list}>
                            <div className={styles.agent_details}>
                                <span>{`${agent.firstname} ${agent.lastname}`}</span>
                                <span>{agent.mobile}</span>
                                <span className={styleStatus(agent.workStatus)}>{formatCallerStatus(agent.workStatus)}</span>
                            </div>
                            <div>
                                <img src={TrashIcon} className={styles.unselect} onClick={() => unselect(idx)}/>
                            </div>
                        </div>
                })
            }
        </div>
        </>
        : null}
        </div>
        {finalSubmit.status == 200 ? <div className={`${styles['success-msg']}`}>{finalSubmit.msg}</div> : null}
        {finalSubmit.status == 400 ? <div className={`${styles['error-msg']}`}>{finalSubmit.msg}</div> : null}
        {/* action footer  */}
        <div className={styles.action_footer}>
        <FutworkButton buttonStyle={'navy--outline non-rounded'} buttonSize={'medium'} disabled={agentsReady ? false : true} onClick={()=> setAgentsReady('')}>Cancel</FutworkButton>
        <FutworkButton buttonStyle={'navy--solid non-rounded'} buttonSize={'medium'} disabled={agentSelected && agentSelected.length > 0 ? false : true} onClick={()=> addAgentToTeam()}>Submit</FutworkButton>    
        </div>
        </div>
    )
}

export default AddAgent