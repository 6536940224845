import React, {useEffect, useState, useContext} from 'react'
import Heading from '../../uicomponents/heading'
import styles from "../../assets/stylesheets/pages/teammanagement/teamlead.module.scss"
import { BackArrow } from "../../assets/images"
import FutworkButton from '../../uicomponents/button'
import Table from '../../uicomponents/table/Table'
import { getTLTeamsListForAdmin, fetchAgentsByTeamLead } from "./service"
import useApi from '../../hooks/useApi'
import { TeamManagementContext } from "./teammanagementContext"
import AgentList from './agentlist'
import constants from '../../util/constants'
import capitalise from '../../helper/Capitalise'
import jwt_decode from "jwt-decode";


const TeamList = (props) => {
    const { 
        agentListView, setAgentListView,
        isTeamPresent, setisTeamPresent 
    } = useContext(TeamManagementContext)

    return(
        <>
          {/* <Heading text = {'Overview'} /> */}
          {isTeamPresent ? agentListView ? <AgentList {...props}/> : <TeamManagement /> : null}
        </>
      
    )
}

// Function to convert team names into slug format
// const slugify = (name) =>
//     name.toLowerCase().replace(/[^a-z0-9 ]/g, "").replace(/\s+/g, "-");
  

const TeamManagement = () => {
    const getTLTeamsListForAdminApi = useApi(getTLTeamsListForAdmin)

    const { 
        agentListView, setAgentListView,
        isTeamPresent, setisTeamPresent,
        loggedInUserRole, loggedInUserName,
        setTeamSelected
    } = useContext(TeamManagementContext)

    const allowedRoles = [constants.customRole.ADMIN, constants.customRole.PROJECT_MANAGER]
    const isAccessGranted = allowedRoles.includes(loggedInUserRole)
    const isTeamLead = loggedInUserRole === constants.customRole.TEAM_LEAD


    let defaultTeamName = ''

    if(isTeamLead) {
        const extractFName = (fullname) => {
            const [first] = fullname
            return first ? capitalise(first).trim() : 'NA'
        }
        const conactTeamName = (fName) => `${fName}'s team`
        const teamName = loggedInUserName ? conactTeamName(extractFName(loggedInUserName.split(' '))) : null
        defaultTeamName = [{teamName: teamName, id: localStorage && jwt_decode(localStorage.authToken).base}]
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("")

    useEffect(() => {
        if(isAccessGranted){
            const skip = (currentPage - 1) * 10
            isTeamPresent && getTLTeamsListForAdminApi.request(skip,10,null,search)
        }
    }, [search, currentPage])


    const {data: teamListForAdmin, loading: teamListForAdminLoading,
    error: teamListForAdminError
    } = getTLTeamsListForAdminApi


    const filterChange = (searchInput) => {
        setSearch(searchInput)
        setCurrentPage(1)
    }

    const getTheAgentsOfTL = (data, props) => {
        // const { agentListView } = useContext(TeamManagementContext)
        // console.log('data', data && data.teamName ? slugify(data.teamName) : null)
        // if(data && data.teamName){
        //     props.history.push({
        //         pathname: `team-management/${slugify(data.teamName)}/agent-list`,
        //     })
        // }
    }

    const columns = [
        {
            Header: 'Team Name',
            // width: "90%",
            accessor: "teamName",
            Cell: ({value}) => <div>{capitalise(value)}</div>,
            disableSortBy: true
        },
        {
            id: 'view',
            Header: '',
            width: "5%",
            Cell: (cell) => {
                return <div className={styles.blue_link} onClick={() => {
                    setAgentListView(true)
                    setTeamSelected(cell.row.original)}
                }>VIEW</div>
            },
            disableSortBy: true
        }
 
    ]

    return(
        <div>
             <div className={styles.head_section}>
                <div className={styles.title_back}>
                <img src={BackArrow} className={styles.arrow} onClick={() => setAgentListView(false)} />
                 <Heading text = {`Team's`} />
                </div>
            </div>

            <Table
            columns={columns}
            data={isTeamPresent ? isTeamLead ? defaultTeamName : teamListForAdmin && teamListForAdmin.data : []}
            type='client'
            height='60'
            totalCount={isTeamPresent ? isTeamLead ? 1 : teamListForAdmin && teamListForAdmin.total : 0}
            limit={10}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            loading={isTeamPresent ? teamListForAdminLoading :false}
            // sortChange={sortChange}
            filterChange={filterChange}
            searchBy='team name'
            isGlobalFilter={isTeamPresent ? true : false}
            />
        </div>
        
    )
}

export default TeamList