import React, {useState, useEffect, useContext} from "react";
import { viewAgentsProjects } from "./service"
import useApi from "../../hooks/useApi";
import { TeamManagementContext } from "./teammanagementContext"
import Table from "../../uicomponents/table/Table";
import capitalise from "../../helper/Capitalise";

const ProjectList = () => {
    const { teamSelected } = useContext(TeamManagementContext)
    const viewAgentsProjectsApi = useApi(viewAgentsProjects)

    useEffect(() => {
        const payload = {
            user: teamSelected.agent.id
            // "607014019be0880017469160"
        }
        viewAgentsProjectsApi.request(payload)
    }, [])

    const columns = [
        {
            Header: 'Project ID',
            accessor: (row) => row.teleproject._id,
            width: '20%',
            Cell: ({cell}) => {
                return <span>{Number(cell.row.id) + 1}.&nbsp;&nbsp;{cell.row.original.teleproject._id}</span>
            },
            disableSortBy: true
        },
        {
            Header: 'Project Title',
            accessor: (row) => row.teleproject.title,
            width: '60%',
            Cell: ({cell}) => {
                return <span>{cell.row.original.teleproject.title}</span>
            },
            disableSortBy: true
        },
        {
            Header: 'Status',
            accessor: (row) => capitalise(row.status),
            width: '10%',
            disableSortBy: true
        },
    ]


    const {data, loading, error} = viewAgentsProjectsApi || {}

    return (
        <div>
            {error && <p>{typeof error == 'object' && 
            error !== null &&
            !Array.isArray(error)
            ? error.error || error.msg : error}</p>}
            <Table
            columns={columns}
            data={data && data.hasOwnProperty('msg') ? [] : data}
            totalCount={data && data.length ? data.length : 0}
            limit={5}
            currentPage={1}
            loading={loading}
            type = 'client'
            isGlobalFilter={true}
            searchBy="project id / title / status"
            />
        </div>
    )
}

export default ProjectList